import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact-container">
      <h2>Contact</h2>
    </div>
  );
}

export default Contact;
