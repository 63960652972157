import React from 'react';
import './Services.css';

function Services() {
  return (
    <div className="services-container">
      <h2>Services</h2>
    </div>
  );
}

export default Services;
