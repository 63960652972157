import axios from 'axios';

// Funções para mostrar e esconder o overlay
let showLoadingOverlay = () => {};
let hideLoadingOverlay = () => {};

export const setOverlayHandlers = (show, hide) => {
  showLoadingOverlay = show;
  hideLoadingOverlay = hide;
};

const setupInterceptors = () => {
  axios.interceptors.request.use(config => {
    showLoadingOverlay();
    return config;
  }, error => {
    hideLoadingOverlay();
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    hideLoadingOverlay();
    return response;
  }, error => {
    hideLoadingOverlay();
    return Promise.reject(error);
  });
};

export default setupInterceptors;
