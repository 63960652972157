import Cookies from 'js-cookie';

const CookieService = {
  set: (name, value, options = {}) => {
    Cookies.set(name, value, options);
  },

  get: (name) => {
    return Cookies.get(name);
  },

  remove: (name) => {
    Cookies.remove(name);
  },

  getUser: () => {
    return Cookies.get('username');
  },

  setUser: (username, token) => {
    Cookies.set('username', username);
    Cookies.set('access_token', token);
  },

  clearUser: () => {
    Cookies.remove('username');
    Cookies.remove('access_token');
  },
};

export default CookieService;
