// src/components/Company/Company.js
import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CancelIcon from '@mui/icons-material/Cancel';
import './Company.css';

function Company() {
  return (
    <div className="company-container">
      <div className="company-header">
        <h2>Os recursos ideais para o seu estabelecimento crescer ainda mais.</h2>
        <p>Negócios como o seu precisam de mais. Para te ajudar a alcançar o topo (ou se manter nele) a Buky oferece duas experiências únicas — uma versão web para tablet e computador e uma versão para celular, onde você poderá gerenciar seu negócio onde quer que você esteja.</p>
      </div>
      <div className="video-background">
        <video autoPlay loop muted>
          <source src={`${process.env.PUBLIC_URL}background-video5.mp4`} type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="company-stats">
          <div className="stat-card">
            <BusinessIcon style={{ fontSize: 50, color: '#3a2e2a' }} />
            <h3>20-30%</h3>
            <p>Mais agendamentos</p>
          </div>
          <div className="stat-card">
            <PeopleIcon style={{ fontSize: 50, color: '#3a2e2a' }} />
            <h3>300</h3>
            <p>Profissionais no Brasil</p>
          </div>
          <div className="stat-card">
            <ThumbUpIcon style={{ fontSize: 50, color: 'green' }} />
            <h3>85%</h3>
            <p>Recomendam a Buky</p>
          </div>
          <div className="stat-card">
            <CancelIcon style={{ fontSize: 50, color: 'red' }} />
            <h3>-25%</h3>
            <p>Agendamentos cancelados</p>
          </div>
        </div>
      </div>
      <div className="company-content">
        <h3>Buky Biz</h3>
        <p>Agenda online, gestão simplificada e marketing.</p>
        <div className="feature">
          <div>
            <h3>Agendamento online</h3>
            <p>Com Buky, os clientes fazem 20-30% mais agendamentos. Através dessa pesquisa feita com os clientes, descobri que 60% das reservas são feitas fora do horário de trabalho.</p>
          </div>
        </div>
        <div className="feature">
          <div>
            <h3>Gestão simplificada</h3>
            <p>Com a plataforma da Buky, gerenciar seu negócio ficou muito mais fácil. Controle seus agendamentos, pagamentos e estoque em um único lugar.</p>
          </div>
        </div>
        <div className="feature">
          <div>
            <h3>Marketing eficiente</h3>
            <p>Aproveite as ferramentas de marketing da Buky para alcançar novos clientes e fidelizar os atuais.</p>
          </div>
        </div>
        <div className="contact-section">
          <h3>Entre em contato</h3>
          <p>Para mais informações, entre em contato conosco em <a href="mailto:contato@buky.com.br">contato@buky.com.br</a>.</p>
        </div>
      </div>
    </div>
  );
}

export default Company;
