import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h2>About</h2>
    </div>
  );
}

export default About;
