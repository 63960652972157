import axios from 'axios';
import { API_BASE_URL } from '../config/config';

export const fetchStates = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/states/1`);
    return response.data;
  } catch (error) {
    console.error('Error fetching states:', error);
    throw error;
  }
};

export const fetchCities = async (stateId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/cities/${stateId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching cities:', error);
    throw error;
  }
};

export const fetchCitiesWithPartners = async (stateID) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/cities-with-partners/${stateID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching cities:', error);
    throw error;
  }
};