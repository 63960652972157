import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LogoutIcon from '@mui/icons-material/Logout';
import Typewriter from 'typewriter-effect';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import OpenAccount from './components/OpenAccount/OpenAccount';
import Company from './components/Company/Company';
import { fetchStates, fetchCities, fetchCitiesWithPartners } from './services/localizationService';
import CookieService from './services/cookieService';
import './App.css';
import Partner from './components/Partner/Partner';

function App() {
  const videoChoices = ['background-video.mp4', 'background-video2.mp4', 'background-video3.mov', 'background-video4.mov'];
  const chosenVideo = videoChoices[Math.floor(Math.random() * videoChoices.length)];
  const [videoSource] = useState(`${process.env.PUBLIC_URL}/${chosenVideo}`);
  const location = useLocation();
  const navigate = useNavigate();
  const [isHome, setIsHome] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    document.title = 'Buky - Barbeshops & Beauty Salons';

    const handleScroll = () => {
      const header = document.querySelector('.main-nav');
      if (!isHome && window.scrollY > 0) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHome]);

  useEffect(() => {
    setIsHome(location.pathname === '/');
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statesData = await fetchStates();
        setStates(statesData);
      } catch (error) {
        console.error('Failed to fetch states:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const fetchData = async () => {
        try {
          const citiesData = await fetchCitiesWithPartners(selectedState);
          setCities(citiesData);
        } catch (error) {
          console.error('Failed to fetch cities:', error);
        }
      };
      fetchData();
    }
  }, [selectedState]);

  useEffect(() => {
    const token = CookieService.get('access_token');
    const user = CookieService.get('username');

    if (token && user) {
      setLoggedIn(true);
      setUsername(user);
    }
  }, []);

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    setSelectedState(stateId);
    setSelectedCity('');
    if (!stateId) {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLoginSuccess = (username) => {
    setLoggedIn(true);
    setUsername(username);
  };

  const handleLogout = () => {
    CookieService.clearUser();
    setLoggedIn(false);
    setUsername('');
  };

  const handleServiceButtonClick = () => {
    if (selectedState && selectedCity) {
      navigate(`/partners/${selectedCity}`);
    }
  };

  const isButtonDisabled = !selectedState || !selectedCity;

  return (
    <div className="App">
      <header className={`App-header ${isHome ? '' : 'reduced-header'}`}>
        {isHome && (
          <div className="video-container">
            <video autoPlay loop muted id="video-background">
              <source src={videoSource} type="video/mp4" />
            </video>
            <div className="overlay"></div>
            <div className="content">
              <div className="text">
                <Typewriter
                  options={{
                    strings: [
                      'Seja ousado',
                      'Seja confiante',
                      'Seja colorido',
                      'Seja livre',
                      'Seja você mesmo'
                    ],
                    autoStart: true,
                    loop: true,
                    delay: 50,
                    deleteSpeed: 30,
                    pauseFor: 3000,
                  }}
                />
              </div>
              <div className="info-text">
                Por enquanto, o serviço está disponível nas seguintes cidades. Por favor, confirme antes de prosseguir.
              </div>
              <div className="dropdowns">
                <select value={selectedState} onChange={handleStateChange} className="state-dropdown">
                  <option value="">Estado</option>
                  {states.map(state => (
                    <option key={state.id} value={state.id}>{state.name}</option>
                  ))}
                </select>
                <select value={selectedCity} onChange={handleCityChange} className="city-dropdown">
                  <option value="">Cidade</option>
                  {cities.map(city => (
                    <option key={city.id} value={city.id}>{city.name}</option>
                  ))}
                </select>
              </div>
              <div className="service-buttons">
                <button className="service-button" onClick={handleServiceButtonClick} disabled={isButtonDisabled}>Buky seu serviço agora!</button>
              </div>
            </div>
          </div>
        )}
        <nav className={`main-nav ${isHome ? '' : 'solid-nav'} ${menuOpen ? 'open' : ''}`}>
          <div className="logo-container">
            <Link to="/"><img src={`${process.env.PUBLIC_URL}/buky_nav.png`} alt="Buky.com.br" className="nav-logo" /></Link>
            <div className="menu-toggle" onClick={handleMenuToggle}>
              <span className="menu-icon">&#9776;</span>
            </div>
          </div>
          <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
            <li><Link to="/company" onClick={() => setMenuOpen(false)}><BusinessIcon className="menu-icon" /><span>Para sua empresa</span></Link></li>
            <li><Link to="/services" onClick={() => setMenuOpen(false)}><PeopleIcon className="menu-icon" /><span>Para você</span></Link></li>
            <li><Link to="/about" onClick={() => setMenuOpen(false)}><InfoIcon className="menu-icon" /><span>Quem somos</span></Link></li>
            <li><Link to="/contact" onClick={() => setMenuOpen(false)}><ContactPhoneIcon className="menu-icon" /><span>Contato</span></Link></li>
          </ul>
          <div className={`nav-buttons ${menuOpen ? 'open' : ''}`}>
            {loggedIn ? (
              <>
                <span className="nav-username">{username}</span>
                <button onClick={handleLogout} className="nav-button"><LogoutIcon className="menu-icon" /><span>Sair</span></button>
              </>
            ) : (
              <>
                <button onClick={() => { setShowLogin(true); setIsSignUp(false); setMenuOpen(false); }} className="nav-button"><LoginIcon className="menu-icon" /><span>Entrar</span></button>
                <button onClick={() => { setShowLogin(true); setIsSignUp(true); setMenuOpen(false); }} className="nav-button special-button"><OpenInNewIcon className="menu-icon" /><span>Quero abrir minha conta</span></button>
              </>
            )}
          </div>
        </nav>
      </header>
      <main className={`App-main ${isHome ? '' : 'reduced-main'}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/open-account" element={<OpenAccount />} />
          <Route path="/partners/:cityID" element={<Partner />} />
          {/* Rota catch-all para páginas não encontradas */}
          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      {showLogin && <Login onClose={() => setShowLogin(false)} isSignUp={isSignUp} onLoginSuccess={handleLoginSuccess} />}
    </div>
  );
}

export default App;
