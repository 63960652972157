import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { loginUser, resetPassword, createUser } from '../../services/authService';
import { fetchGenderIdentities } from '../../services/genderIdentityService';
import './Login.css';
import CookieService from '../../services/cookieService';

const Login = ({ onClose, isSignUp, onLoginSuccess }) => {
  const [currentView, setCurrentView] = useState(isSignUp ? 'register' : 'login');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [genderIdentityID, setGenderIdentityID] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [genderIdentities, setGenderIdentities] = useState([]);

  useEffect(() => {
    const getGenderIdentities = async () => {
      try {
        const data = await fetchGenderIdentities();
        setGenderIdentities(data);
      } catch (error) {
        console.error('Error fetching gender identities:', error);
      }
    };

    getGenderIdentities();
  }, []);

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleGenderIdentityChange = (e) => setGenderIdentityID(e.target.value);
  const handleBirthDateChange = (e) => setBirthDate(e.target.value);
  const handleRegisterPasswordChange = (e) => setRegisterPassword(e.target.value);

  const resetForm = () => {
    setUsername('');
    setPassword('');
    setEmail('');
    setFirstName('');
    setLastName('');
    setPhone('');
    setGenderIdentityID('');
    setBirthDate('');
    setRegisterPassword('');
    setError('');
    setSuccessMessage('');
  };

  const validateBirthDate = (date) => {
    const [day, month, year] = date.split('/');
    const birthDate = new Date(`${year}-${month}-${day}`);
    const today = new Date();
    return birthDate <= today && birthDate.getFullYear() > 1900;
  };

  const validatePhone = (phone) => {
    const phoneRegex = /\(\d{2}\) \d{5}-\d{4}/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      const response = await loginUser(username, password);
      CookieService.setUser(username, response.access_token);
      onLoginSuccess(username);

      resetForm();
      onClose();
    } catch (error) {
      console.error('Error during login:', error.message);
      setError('Erro ao tentar fazer login. Verifique suas credenciais.');
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!validateBirthDate(birthDate)) {
      setError('Data de nascimento inválida.');
      return;
    }

    if (!validatePhone(phone)) {
      setError('Telefone inválido.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Email inválido.');
      return;
    }

    if (registerPassword.length > 15) {
      setError('A senha deve ter no máximo 15 caracteres.');
      return;
    }

    const newUser = {
      username: email,
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      gender_identity_id: parseInt(genderIdentityID),
      birth_date: new Date(birthDate.split('/').reverse().join('-')).toISOString().split('T')[0],
      password: registerPassword,
      provider: 'common'
    };

    try {
      const response = await createUser(newUser);
      console.log('User created successfully:', response);

      setSuccessMessage('Usuário criado com sucesso. Verifique seu e-mail para o link de verificação.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      if (error.message === 'Username or email already exists') {
        setError('Usuário ou e-mail já existe.');
      } else {
        setError('Erro ao tentar criar o usuário.');
      }
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      const response = await resetPassword(username);
      console.log('Password reset successful:', response);

      setSuccessMessage('Senha redefinida com sucesso. Por favor, verifique seu e-mail para a nova senha e o link de redefinição.');
    } catch (error) {
      console.error('Error during password reset:', error.message);
      setError('Erro ao tentar redefinir a senha. Verifique se o e-mail está correto.');
    }
  };

  const renderLoginForm = () => (
    <form className="login-form" onSubmit={handleLoginSubmit}>
      <label>
        <input type="email" placeholder="Endereço de e-mail" value={username} onChange={handleUsernameChange} required maxLength="100" />
      </label>
      <label>
        <input type="password" placeholder="Senha" value={password} onChange={handlePasswordChange} required />
      </label>
      <label><button type="submit">Continuar</button></label>
      {error && <p className="error-message">{error}</p>}
      <label><button type="button" className="link-button" onClick={() => setCurrentView('register')}>Não tem uma conta?</button></label>
      <label><button type="button" className="link-button" onClick={() => setCurrentView('forgot-password')}>Esqueceu sua senha?</button></label>
    </form>
  );

  const renderRegisterForm = () => (
    <form className="login-form" onSubmit={handleRegisterSubmit}>
      <div className="inline-inputs">
        <label>
          <input type="text" placeholder="Nome" value={firstName} onChange={handleFirstNameChange} required maxLength="50" />
        </label>
        <label>
          <input type="text" placeholder="Sobrenome" value={lastName} onChange={handleLastNameChange} required maxLength="50" />
        </label>
      </div>
      <label>
        <input type="email" placeholder="Endereço de e-mail" value={email} onChange={handleEmailChange} required maxLength="100" />
      </label>
      <div className="inline-inputs">
        <label>
          <InputMask mask="(99) 99999-9999" placeholder="Telefone" value={phone} onChange={handlePhoneChange} required />
        </label>
        <label>
          <InputMask mask="99/99/9999" placeholder="Data de Nascimento" value={birthDate} onChange={handleBirthDateChange} required />
        </label>
      </div>
      <label>
        <select value={genderIdentityID} onChange={handleGenderIdentityChange} required>
          <option value="0">Selecione sua orientação de gênero</option>
          {genderIdentities.map((gender) => (
            <option key={gender.ID} value={gender.ID}>{gender.name}</option>
          ))}
        </select>
      </label>
      <label>
        <input type="password" placeholder="Senha" value={registerPassword} onChange={handleRegisterPasswordChange} required maxLength="15" />
      </label>
      <label><button type="submit">Cadastrar</button></label>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <label><button type="button" className="link-button" onClick={() => setCurrentView('login')}>Já tem uma conta?</button></label>
    </form>
  );

  const renderForgotPasswordForm = () => (
    <form className="login-form" onSubmit={handleResetPasswordSubmit}>
      <label>
        <input type="email" placeholder="Endereço de e-mail" value={username} onChange={handleEmailChange} required maxLength="100" />
      </label>
      <label><button type="submit">Enviar</button></label>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <label><button type="button" className="link-button" onClick={() => setCurrentView('login')}>Lembrou sua senha?</button> </label>
    </form>
  );

  return (
    <div className="login-overlay">
      <div className="login-container">
        <button className="close-button" onClick={() => { resetForm(); onClose(); }}>×</button>
        <h2>Que bom que você está aqui</h2>
        {currentView === 'login' && renderLoginForm()}
        {currentView === 'register' && renderRegisterForm()}
        {currentView === 'forgot-password' && renderForgotPasswordForm()}
        <div className="sso-buttons">
          <button className="sso-button google">Continuar com o Google</button>
          <button className="sso-button microsoft">Continuar com a conta Microsoft</button>
          <button className="sso-button apple">Continuar com a Apple</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
