import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Partner.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneIcon from '@mui/icons-material/Phone';
import { API_BASE_URL } from '../../config/config';

const Partner = () => {
  const { cityID } = useParams();
  const [partners, setPartners] = useState([]);
  const [serviceType, setServiceType] = useState('all');
  const [priceOrder, setPriceOrder] = useState('asc');
  const [searchName, setSearchName] = useState('');
  const [serviceTypes, setServiceTypes] = useState([]);

  useEffect(() => {
    // Fetch partners based on cityID
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/partners/${cityID}`);
        const updatedPartners = response.data.map(partner => {
          if (partner.services && partner.services.length > 0) {
            const minPriceService = partner.services.reduce((min, service) => service.price < min.price ? service : min);
            return {
              ...partner,
              minPrice: minPriceService.price,
              minDuration: minPriceService.duration
            };
          } else {
            return {
              ...partner,
              minPrice: 0,
              minDuration: 0
            };
          }
        });

        setPartners(updatedPartners);

        const types = [...new Set(response.data.map(partner => partner.service))];
        setServiceTypes(types);
      } catch (error) {
        console.error('Error fetching partners:', error);
      }
    };

    fetchPartners();
  }, [cityID]);

  const handleServiceTypeChange = (e) => setServiceType(e.target.value);
  const handlePriceOrderChange = (e) => setPriceOrder(e.target.value);
  const handleSearchNameChange = (e) => setSearchName(e.target.value);

  const filteredPartners = partners.filter(partner => {
    const matchesServiceType = serviceType === 'all' || partner.service === serviceType;
    const matchesSearchName = partner.name.toLowerCase().includes(searchName.toLowerCase());
    return matchesServiceType && matchesSearchName;
  });

  const sortedPartners = filteredPartners.sort((a, b) => {
    const priceA = a.minPrice;
    const priceB = b.minPrice;
    return priceOrder === 'asc' ? priceA - priceB : priceB - priceA;
  });

  return (
    <div className="partner-container">
      <main className="partner-main">
        <aside className="filters">
          <h2>Filtros</h2>
          <label htmlFor="serviceType">Tipo de Serviço</label>
          <select id="serviceType" onChange={handleServiceTypeChange}>
            <option value="all">Todos</option>
            {serviceTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>
          <label htmlFor="priceOrder">Ordenar por Preço</label>
          <select id="priceOrder" onChange={handlePriceOrderChange}>
            <option value="asc">Menor para Maior</option>
            <option value="desc">Maior para Menor</option>
          </select>
          <label htmlFor="searchName">Buscar por Nome</label>
          <input type="text" id="searchName" placeholder="Digite o nome" onChange={handleSearchNameChange} />
        </aside>
        <section className="results">
          {sortedPartners.map(partner => (
            <article key={partner.id} className="result-card">
              <div className="image-container">
                <img src={partner.img} alt="Serviço" />
              </div>
              <div className="info-container">
                <h2>{partner.name}</h2>
                <p>{partner.address}</p>
                <p><b>{partner.service}</b></p>
                <p>{partner.description}</p>
                <div className="service-details">
                  <div className="service-icons">
                    <span><AccessTimeIcon style={{ color: '#888' }} /> {partner.minDuration} min</span>
                    <span><PhoneIcon style={{ color: '#888' }} /> {partner.phone}</span>
                  </div>
                  <div className="price-button">
                    <button>Reservar</button>
                    <div className="service-icons">
                      <span><AttachMoneyIcon style={{ color: '#888' }} /> A partir de R$ {partner.minPrice.toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </section>
      </main>
      <footer>
        <p>© 2024 Buky</p>
      </footer>
    </div>
  );
}

export default Partner;
