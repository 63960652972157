import axios from 'axios';
import { API_BASE_URL } from '../config/config';

export const loginUser = async (username, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, { username, password });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const resetPassword = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/reset-password`, { email });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const createUser = async (user) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/create-user`, user);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      throw new Error('Username or email already exists');
    } else {
      console.error('Error creating user:', error);
      throw new Error('Error creating user');
    }
  }
};
