import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import setupInterceptors, { setOverlayHandlers } from './interceptors/axiosInterceptor';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import './index.css';

const Root = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Setting up interceptors");
    setOverlayHandlers(() => setLoading(true), () => setLoading(false));
    setupInterceptors();
  }, []);

  return (
    <>
      <LoadingOverlay loading={loading} />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Root />);
