import React from 'react';
import './OpenAccount.css';

function OpenAccount() {
  return (
    <div className="openaccount-container">
      <h2>OpenAccount</h2>
    </div>
  );
}

export default OpenAccount;
